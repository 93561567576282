import React, { useEffect, useState } from 'react';
import { Form, Button, Select, Spin } from 'antd';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import _ from 'underscore';

import { fetchProducts, setFilter, fetchAllSearchResults, exportProducts } from '../../redux/productSlice';

import { Pagination, Product, ProductSearchBar } from '../../components';
import ProductImporter from './ProductImporter';

import './Products.scss';

function Products() {
  const { Option } = Select;
  const [form] = Form.useForm();

  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { filter, products, count } = useSelector((state) => state.products);
  const { vendorsWithProject, subVendors } = useSelector((state) => state.vendors);  
  
  const { tags } = useSelector((state) => state.tags);
  const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);

  const [localSearchText, setLocalSearchText] = useState('');
  const [listAllResults, setListAllResults] = useState(false);
  const [allResultsPath, setAllResultsPath] = useState("name");
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [importerVisibility, setImporterVisibility] = useState(false);

  const handleFilter = (payload) => {
    dispatch(setFilter(payload));
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let isCancelled = false;
    
      const fetchData = async () => {
        setLoading(true)
        try {
          if(!listAllResults) {
            await dispatch(fetchProducts({query: filter, signal}));
          }
        } catch (error) {
        } finally {
          if (!isCancelled) {
            setLoading(false); 
          }
        }
      };
    
      fetchData(); 
      
      return () => {
        isCancelled = true;
        controller.abort();
      };

  }, [filter, dispatch]);

  useEffect(() => {
    if(listAllResults) {
      dispatch(fetchAllSearchResults({ ...filter, query: localSearchText, path: allResultsPath }));
      setListAllResults(false);
    }
  }, [listAllResults]);

  const clearSearch = () => {
    dispatch(fetchProducts({ query: filter }));
  }  

  const initialFilterData = {
    sortBy: '{}',
    vendor: 'all',
    subVendor: 'all',
    type: 'all',
    tag: 'all',
    status: 'all',
    auto: 'all',
    images: 'all',
    stock: 'all',
    stock_box: '{}'
  };
  
  const [filterData, setFilterData] = useState(initialFilterData);


  const handleFilterChange = (newFilterData) => {
    setFilterData(newFilterData);
  };
  

  return (
    <div className="list-products-container">

      <ProductImporter 
        modalVisibility={importerVisibility}
        setModalVisibility={setImporterVisibility}
      />

      <div className="list-products-nav">
        <h2>Products</h2>
        <div>
          <Button 
            type="text"
            loading={exportLoading}
            onClick={() => {
              setExportLoading(true);

              exportProducts(filter)
                .then(async (responseUrl) => {
                  setTimeout(async () => {
                    const response = await fetch(responseUrl?.data, {
                      headers: {
                        // Add any required headers here
                      },
                    });
                    if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
                    const blob = await response.blob();
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', "urunler.xlsx"); // Set the download filename
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
  
                    setExportLoading(false);
                  }, 1000)
                })
                .catch((err) => {
                  setExportLoading(false);
                })
            }}
          >
            { t("fields.product_import.export") }
          </Button>
          <Button type="text" onClick={() => setImporterVisibility(true)}>{ t("fields.product_import.import") }</Button>
          <Button onClick={() => navigate(`/product/new`)} type="text">{ t(`buttons.Add New Product`) }</Button>
        </div>
      </div>
      <div className='list-number-of-products'>
        Number of products: {count}
      </div>
      <div className="list-products-filter">
        <div className='search-products'>
          <ProductSearchBar 
            type="product" 
            setListAllResults={setListAllResults}
            localSearchText={localSearchText}
            setLocalSearchText={setLocalSearchText}
            setAllResultsPath={setAllResultsPath}
            clearSearch={clearSearch}
          />
        </div>
        <Form 
          form={form}
          layout="inline"
          initialValues={{
            vendor: filter?.filter?.["vendor._id"] ? filter?.filter?.["vendor._id"] : "all",
            subVendor: filter?.filter?.["sub_vendor_name"] ? filter?.filter?.["sub_vendor_name"] : "all",
            product_type: filter?.filter?.product_type ? filter?.filter?.product_type : "all",
            tag: filter?.filter?.tags ? filter.filter.tags[0] : "all",
            status: filter?.filter?.status ? filter?.filter?.status : "all",
            auto: filter?.filter?.auto_generated ? filter?.filter?.auto_generated : "all",
            images: filter?.filter?.images ? filter?.filter?.images : "all",
            stock: filter?.filter?.stock ? filter?.filter?.stock : "all",
            new_image: filter?.filter?.new_image ? filter?.filter?.new_image : "all",
            no_invoice: filter?.filter?.stock_box?.no_invoice ? filter?.filter?.stock_box?.no_invoice : "all",
          }}
        >

          <div className='inline-inputs-products'>
            <Form.Item name="vendor">
              <Select
                onChange={(e) => {
                  if (e === 'all') {
                    handleFilter({...filter , filter: (_.omit(filter.filter,'vendor._id'))});
                    handleFilterChange({ ...filterData, vendor: 'vendor'})
                  } else {
                    handleFilter({ ...filter, filter: { ...filter.filter, ["vendor._id"]: [e] } });
                    handleFilterChange({ ...filterData, vendor: e})
                  }
                }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                <Option value="all">All Vendors</Option>
                {vendorsWithProject?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                  <Option key={'vendor-select' + index} value={vendor._id}>
                    {vendor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="subVendor">
              <Select
                onChange={(e) => {                  
                  if (e === 'all') {
                    handleFilter({...filter , filter: (_.omit(filter.filter,'sub_vendor_name'))});
                    handleFilterChange({ ...filterData, subVendor: 'subVendor'})
                  } else {
                    handleFilter({ ...filter, filter: { ...filter.filter, "sub_vendor_name": e } });
                    handleFilterChange({ ...filterData, subVendor: e})
                  }
                }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                <Option value="all">All Sub Vendors</Option>
                {subVendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((subVendor, index) => (
                  <Option key={'sub-vendor-select' + index} value={subVendor.name}>
                    {subVendor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>  

            <Form.Item name="product_type">
              <Select
                onChange={(e) => {
                  if (e === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'product_type'))});
                    handleFilterChange({ ...filterData, type: 'product_type'})
                  } else {
                    handleFilter({ ...filter, filter: { ...filter.filter, product_type: e } });
                    handleFilterChange({ ...filterData, type: e})
                  }
                }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                <Option value="all">All Types</Option>
                {productTypes?.slice().sort((a, b) => a.names?.en?.localeCompare(b.names?.en)).map((type, index) => (
                  <Option key={'vendor-select' + index} value={type._id}>
                    {type?.names?.en}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="tag">
              <Select
                onChange={(e) => {
                  if (e === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'tags'))});
                    handleFilterChange({ ...filterData, tag: 'all'})
                  } else {
                    handleFilter({ ...filter, filter: { ...filter.filter, tags: [e] } });
                    handleFilterChange({ ...filterData, tag: e})
                  }
                }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                <Option value="all">All Tags</Option>
                {tags?.slice().sort((a, b) => a?.names?.en.localeCompare(b?.names?.en)).map((tag, index) => (
                  <Option key={'tag-select' + index} value={tag?._id}>
                    {tag?.names?.en}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="status">
              <Select
                onChange={(e) => {
                  if (e === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'status'))});
                    handleFilterChange({ ...filterData, status: 'all'})
                  } else {
                    handleFilter({ ...filter, filter: { ...filter.filter, status: e } });
                    handleFilterChange({ ...filterData, status: e})
                  }
                }}
              >
                <Option value="all">All Status</Option>
                <Option value="Active">Active</Option>
                <Option value="Passive">Passive</Option>
                <Option value="Draft">Draft</Option>
                <Option value="Archived">Archived</Option>
              </Select>
            </Form.Item>
            <Form.Item name="new_image">
              <Select
                onChange={(e) => {
                  if (e === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'new_image'))});
                    handleFilterChange({ ...filterData, new_image: 'all'})
                  } else {
                    handleFilter({ ...filter, filter: { ...filter.filter, new_image: e } });
                    handleFilterChange({ ...filterData, new_image: e})
                  }
                }}
              >
                <Option value="all">All Image Statuses</Option>
                <Option value="content">Content Change</Option>
                <Option value="count">Count Change</Option>
                <Option value="url">URL Change</Option>
                <Option value="no-change">No Change</Option>
              </Select>
            </Form.Item>
            <Form.Item name="auto">
              <Select
                defaultValue='all'
                onChange={(e) => {
                  if (e === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'auto_generated'))});
                    handleFilterChange({ ...filterData, auto: 'all'})
                  }else if (e === true){
                    handleFilter({ ...filter, filter: { ...filter.filter, auto_generated: e } });
                    handleFilterChange({ ...filterData, status: e})
                  }
                  else if(!e) {
                    handleFilter({ ...filter, filter: { ...filter.filter, auto_generated: { $exists : false} } });
                    handleFilterChange({ ...filterData, status: e})
                  }
                }}
              >
                <Option value="all">All Entry Types</Option>
                <Option value={true}>Auto</Option>
                <Option value={false}>Manuel</Option>
              </Select>
            </Form.Item>

            <Select
              onChange={(value) => {
                const newValue = JSON.parse(value);
                handleFilter({ ...filter, sort: newValue });
                handleFilterChange({ ...filterData, sortBy: value})
              }}
              value={filterData.sortBy}
            >
              <Option value='{}'>Sort</Option>
              <Option value='{"cogs": 1}'>
                Price: Low to High
              </Option>
              <Option value='{"cogs": -1}'>
                Price: High to Low
              </Option>
              <Option value='{ "names.en": 1}'>
                Name: A to Z
              </Option>
              <Option value='{ "names.en": -1}'>
                Name: Z to A
              </Option>
              <Option value='{"createdAt": -1 }'>
                Date: New to Old
              </Option>
              <Option value='{"createdAt": 1 }'>
                Date: Old to New
              </Option>
              <Option value='{"in_transit_info.date": 1 }'>
                In Transit: Old to New
              </Option>
              <Option value='{"in_transit_info.date": -1 }'>
                In Transit: New to Old
              </Option>
            </Select>

            <Form.Item name="images">
                <Select
                    onChange={(e) => {
                        if (e === 'all') {
                            handleFilter({ ...filter, filter: { ...filter.filter, images: "all" } });
                            handleFilterChange({ ...filterData, images: 'all'})

                        } else {
                            handleFilter({ ...filter, filter: { ...filter.filter, images: e } });
                            handleFilterChange({ ...filterData, images: e})

                        }
                    }}
                >
                    <Option value="all">{t(`fields.product.Photo All`)}</Option>
                    <Option value="yes">{t(`fields.product.Need Photo`)}</Option>
                    <Option value="no">{t(`fields.product.No Need Photo`)}</Option>
                </Select>
            </Form.Item>

            <Form.Item name="stock">
                <Select
                    onChange={(e) => {
                        if (e === 'all') {
                            handleFilter({ ...filter, filter: { ...filter.filter, stock: "all" } });
                            handleFilterChange({ ...filterData, stock: 'all'})

                        } else {
                            handleFilter({ ...filter, filter: { ...filter.filter, stock: e } });
                            handleFilterChange({ ...filterData, stock: e})

                        }
                    }}
                >
                    <Option value="all">Stock: All</Option>
                    <Option value="stock">Stock In</Option>
                    <Option value="in_transit">In Transit</Option>
                </Select>
            </Form.Item>

            <Form.Item name="no_invoice">
                <Select
                    onChange={(e) => {
                        if (e === 'all') {
                            handleFilter({ ...filter, filter: { ...filter.filter, stock_box: {} } });
                            handleFilterChange({ ...filterData, stock_box: {} })

                        } else {
                          handleFilter({ ...filter, filter: { ...filter.filter, stock_box: {...filter.filter?.stock_box, no_invoice: e} } });
                            handleFilterChange({ ...filterData, stock_box: {...filterData.stock_box, no_invoice: e} })

                        }
                    }}
                >
                    <Option value="all">No Invoice: All</Option>
                    <Option value="true">No Invoice: True</Option>
                    <Option value="false">No Invoice: False</Option>
                </Select>
            </Form.Item>
          </div>



          

          
        </Form>
      </div>
      <div className="list-product-table-head">
        <div className="list-product-table-check">*</div>
        <div className="list-product-table-product">Product</div>
        <div className="list-product-table-status">Status</div>
        <div className="list-product-table-tag">Tag</div>
        <div className="list-product-table-type">Type</div>
        <div className="list-product-table-vendor">Vendor</div>
        <div className="list-product-table-price">Price</div>
      </div>
      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Products..."} />}
      {products?.map((item) => (
        <Product key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={handleFilter} />
      </div>
    </div>
  );
}

export default Products;
