import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import store from './store'
import { getUrlFromState } from './userSlice';


export const fetchVendors = createAsyncThunk(
  'products/fetchVendors',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/vendor`, {
      filter,
    });

    return data;
  },
);

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/collection/category/all`);
    return data;
  },
);


export const fetchCreate = (vendor) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/vendor/create`, vendor);
}

export const fetchUpdateStatus = (id, status) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/vendor/one/${id}`, {
  vendorUpdates: {
    status,
  },
  productsUpdates: {
    status,
  },
})};

export const fetchUpdate = (id, update, language) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/vendor/basic-update/${id}`, {
  update, language
})};

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  
  return axios.delete(`${url}/vendor/one/${id}`);
}

export const fetchShutDown = createAsyncThunk(
  'vendor/shutdown',
  async (id) => {
    try {
      const url = getUrlFromState(store)
      const response = await axios.patch(`${url}/vendor/shut-down/${id}`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }
);

export const fetchOpenVendor = createAsyncThunk(
  'vendor/open',
  async (id) => {
    try {
      const url = getUrlFromState(store)
      const response = await axios.patch(`${url}/vendor/open/${id}`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }
);

export const fetchVendorProducts = (id, products) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/vendor/product-images/${id}`, { products })
};


export const fetchSubVendors = createAsyncThunk(
  'products/fetchSubVendors',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/sub-vendor/list`);
    return data;
  },
);

export const fetchVendorsWithProject = createAsyncThunk(
  'products/fetchVendorsWithProject',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/vendor`, {
      filter,
      type : "general"
    });

    return data;
  },
);

export const fetchVendorsForProduct = createAsyncThunk(
  'products/fetchVendorsForProduct',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/vendor`, {
      filter,
      type : "product"
    });

    return data;
  },
);

const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    filter: {},
    vendors: [],
    subVendors: [],
    categories: [],
    vendorsWithProject: [],
    vendorsForProduct: [],
    status: {
      fetchVendors: 'idle',
      fetchSubVendors: 'idle',
      fetchVendorsWithProject: 'idle',
      fetchVendorsForProduct: 'idle'
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [fetchVendors.pending]: (state) => {
      state.status.fetchVendors = 'pending';
    },
    [fetchVendors.fulfilled]: (state, action) => {
      state.status.fetchVendors = 'succeeded';
      state.vendors = action.payload;
    },
    [fetchVendors.rejected]: (state, action) => {
      state.status.fetchVendors = 'rejected';
      state.error = action.payload;
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
    },
    [fetchSubVendors.pending]: (state) => {
      state.status.fetchSubVendors = 'pending';
    },
    [fetchSubVendors.fulfilled]: (state, action) => {
      state.status.fetchSubVendors = 'succeeded';
      state.subVendors = action.payload;
    },
    [fetchSubVendors.rejected]: (state, action) => {
      state.status.fetchSubVendors = 'rejected';
      state.error = action.payload;
    },
    [fetchVendorsWithProject.pending]: (state) => {
      state.status.fetchVendorsWithProject = 'pending';
    },
    [fetchVendorsWithProject.fulfilled]: (state, action) => {
      state.status.fetchVendorsWithProject = 'succeeded';
      state.vendorsWithProject = action.payload;
    },
    [fetchVendorsWithProject.rejected]: (state, action) => {
      state.status.fetchVendorsWithProject = 'rejected';
      state.error = action.payload;
    },
    [fetchVendorsForProduct.pending]: (state) => {
      state.status.fetchVendorsForProduct = 'pending';
    },
    [fetchVendorsForProduct.fulfilled]: (state, action) => {
      state.status.fetchVendorsForProduct = 'succeeded';
      state.vendorsForProduct = action.payload;
    },
    [fetchVendorsForProduct.rejected]: (state, action) => {
      state.status.fetchVendorsForProduct = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter, setVendors } = vendorSlice.actions;

export default vendorSlice.reducer;
