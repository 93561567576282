import { SupplierInvoice, Pagination } from '../../components';
import { useTranslation } from "react-i18next";
import './SupplierInvoices.css';
import { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { fetchSupplierInvoices } from '../../redux/supplierInvoiceSlice';
import { Form, Select } from 'antd';
import _ from 'underscore';


function SupplierInvoices() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { t } = useTranslation('translation');
  const [filter,setFilter] = useState({ limit: 100, page: 1, sort: { "createdAt" : -1 } })
  const { vendorsWithProject } = useSelector((state) => state.vendors);
  const [invoices, setInvoices]  = useState([])


  useEffect(() => {
    fetchSupplierInvoices(filter).then(
      (res) => {
        setInvoices(res.data)
      }
    ).catch((err) => {
      console.log(err)
      if (err?.response?.status === 404) {
        setInvoices([])
      }
    })
   
  }, [filter])


  return (
    <div className="list-supplier-invoices-container">
      <div className="list-supplier-invoices-nav">
          <h2>{t('home.Invoices')}</h2>
      <div/>
      </div>
      <div className="list-supplier-invoices-filter" >
                <Form
                    form={form}
                    layout="inline"
                    initialValues={{
                        date: '{"createdAt": -1}',
                        vendor : 'all'
                    }}
                  
                >   
                    <Form.Item name="date" style={{ width: '200px' }}>
                        <Select
                            onChange={(value) => {
                              const newValue = JSON.parse(value);
                              setFilter({...filter , sort : newValue})}}
                        >
                            <Option value='{}'>{t(`sort.Sort`)}</Option>
                            <Option value='{"createdAt": -1}'>{t(`sort.Date_New to Old`)}</Option>
                            <Option value='{"createdAt": 1}'>{t(`sort.Date_Old to New`)}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="vendor">
                    <Select
                    onChange={(e) => {
                        if (e === 'all') {
                        setFilter({...filter , filter : (_.omit(filter.filter,'vendor'))});
                        } else {
                        setFilter({ ...filter, filter: { ...filter.filter, vendor: [e] } });
                        }
                    }}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "120px" }}
                    >
                    <Option value="all">All Vendors</Option>
                    {vendorsWithProject?.filter(vendor => vendor?.status === 'Active').slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                        <Option key={'vendor-select' + index} value={vendor._id}>
                        {vendor.name}
                        </Option>
                    ))}
                    </Select>
                </Form.Item>
                </Form>
            </div>
      <div className="list-supplier-invoice-table-head">
        <div className="list-supplier-invoice-table-check">*</div>
        <div className="list-supplier-invoice-table-code">{t(`fields.invoice.Code`)}</div>
        <div className="list-supplier-invoice-table-vendor">{t(`home.vendor`)}</div>
        <div className="list-supplier-invoice-table-created-at">{t(`fields.invoice.Created At`)}</div>
      </div>
      {invoices?.map((item) => (
        <SupplierInvoice key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter}/>
      </div>
    </div>
  );
}

export default SupplierInvoices;
