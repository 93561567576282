import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import axios from 'axios';
import store from './store'

const initialState = {
    orderProductsByVendor: [],
    allInvoices: [],
    invoice: {},
    filter: {
        page: 1,
        limit: 100,
        vendor: "",
        subVendor: "",
        sort: { invoice_date: -1 },
        hasMissingProduct: null
    },
    status: {
        orderProductsByVendor: "idle",
        allInvoices: "idle",
        invoice: "idle"
    },
    errors: {
        orderProductsByVendor: null,
        allInvoices: null,
        invoice: null
    }
};

export const fetchNoInvoiceOrderProductsByVendor = createAsyncThunk("loncaInvoice/fetchNoInvoiceOrderProductsByVendor",
    async ({
        selectedVendor,
        selectedSubVendor,
        hasAll = false,
        filter = { page: 1, limit: 100, search: "", sort: { createdAt: "asc" } }
    }) => {
        const url = getUrlFromState(store);
        const { data } = await axios.post(`${url}/lonca-invoices/products-by-vendor`, { vendorName: selectedVendor, subVendorName: selectedSubVendor, hasAll: hasAll, filter: filter });
        return data;
    }
);

export const fetchAllLoncaInvoices = createAsyncThunk("loncaInvoice/fetchAllLoncaInvoices", 
    async ({
        page=1,
        limit=100,
        sort,
        vendor,
        subVendor,
        hasMissingProduct
    }) => {
        const url = getUrlFromState(store);
        const { data } = await axios.post(`${url}/lonca-invoices/get-all`, { 
            page: page,
            limit: limit,
            sort: sort,
            vendor: vendor,
            subVendor: subVendor,
            hasMissingProduct: hasMissingProduct
        });
        return data;
    }
)

export const createLoncaInvoice = async({
    selectedProducts,
    selectedVendor,
    selectedSubVendor,
    invoiceNumber,
    parasutId,
    invoiceDate
}) => {
    const url = getUrlFromState(store);
    const { data } = await axios.post(`${url}/lonca-invoices/create`, { 
        products: selectedProducts, 
        vendorName: selectedVendor,
        subVendorName: selectedSubVendor,
        parasutId, parasutId,
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate
    });
    return data;
}

export const updateLoncaInvoice = async({
    _id,
    selectedProducts,
    selectedVendor,
    selectedSubVendor,
    invoiceNumber,
    parasutId,
    invoiceDate,
    hasMissingProduct
}) => {
    const url = getUrlFromState(store);
    const { data } = await axios.patch(`${url}/lonca-invoices/update`, {
        _id: _id,
        products: selectedProducts, 
        vendorName: selectedVendor,
        subVendorName: selectedSubVendor,
        invoiceNumber: invoiceNumber,
        parasutId: parasutId,
        invoiceDate: invoiceDate,
        hasMissingProduct: hasMissingProduct
    });
    return data;
}

export const fetchInvoiceById = createAsyncThunk("loncaInvoice/fetchInvoiceById", 
    async({
        _id
    }) => {
        const url = getUrlFromState(store);
        const { data } = await axios.get(`${url}/lonca-invoices/${_id}`);
        return data;
    }
)

export const autoInvoiceEnter = async({
    _id,
}) => {
    const url = getUrlFromState(store);
    const { data } = await axios.patch(`${url}/lonca-invoices/auto-invoice-enter`, {
        _id,
    });
    return data;
}

const loncaInvoiceSlice = createSlice({
    name: "loncaInvoice",
    initialState: initialState,
    reducers: {
        setLoncaInvoiceFilter: (state, action) => {
            state.filter = action.payload;
        },
        resetOrderProductsByVendor: (state, action) => {
            state.orderProductsByVendor = [];
            state.status.orderProductsByVendor = "idle";
        }
    },
    extraReducers: {
        /** Invoice Products by Vendor */
        [fetchNoInvoiceOrderProductsByVendor.pending]: (state) => {  
            state.status.orderProductsByVendor = "pending";
        },
        [fetchNoInvoiceOrderProductsByVendor.fulfilled]: (state, action) => {
            state.status.orderProductsByVendor = "fulfilled";
            state.orderProductsByVendor = action.payload;
        },
        [fetchNoInvoiceOrderProductsByVendor.rejected]: (state, action) => {
            state.status.orderProductsByVendor = "rejected";
            state.errors.orderProductsByVendor = action.error.message;
        },

        /** All Invoices */
        [fetchAllLoncaInvoices.pending]: (state) => {
            state.status.allInvoices = "pending";
        },
        [fetchAllLoncaInvoices.fulfilled]: (state, action) => {
            state.status.allInvoices = "fulfilled";
            state.allInvoices = action.payload;
        },
        [fetchAllLoncaInvoices.rejected]: (state, action) => {
            state.status.allInvoices = "rejected";
            state.errors.allInvoices = action.error.message;
        },

        /** Invoice by Id */
        [fetchInvoiceById.pending]: (state) => {
            state.status.invoice = "pending";
        },
        [fetchInvoiceById.fulfilled]: (state, action) => {
            state.status.invoice = "fulfilled";
            state.invoice = action.payload;
        },
        [fetchInvoiceById.rejected]: (state, action) => {
            state.status.invoice = "rejected";
            state.errors.invoice = action.error.message;
        }
    }
});

export const { setLoncaInvoiceFilter, resetOrderProductsByVendor } = loncaInvoiceSlice.actions;
export default loncaInvoiceSlice.reducer;

