import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';


const SeriesSelector = ({ product, setProduct, setIsDirty, createChangeLog, productInitial }) => {

    const { allSeries: seriesMain } = useSelector((state) => state.series);
    const [series, setSeries] = useState(seriesMain);

    useEffect(() => {
      setSeries(seriesMain);
    }, [seriesMain]);

    useEffect(() => {
      if (product) {
        if (product.series) {
          const product_series = typeof product.series === "object" ? product?.series?._id : product.series;
          const selectedSerie = series.find((serie) => serie?._id === product_series);
          setSeries(series => [
            selectedSerie,
            ...series.filter((serie) => serie?._id !== selectedSerie?._id)
          ]);
        }
      }
    }, [product?.series]);

    const handleSeriesChange = (e) => {
      setProduct({
        ...product,
        series: e,
        update_config: {...product?.update_config, series: 'fixed'}
      });
      setIsDirty(true);
      createChangeLog('Series', productInitial?.series?._id, e);
    };

    return (
      <Form.Item name="series" label="Series">
        <Select
          onChange={handleSeriesChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value={null}>None</Select.Option>

          {series.map((serie, index) => (
            <Select.Option key={'series-select' + index} value={serie?._id}>
              {serie?.name}
            </Select.Option>
          ))}

        </Select>
      </Form.Item>
    );
};

export default SeriesSelector;
