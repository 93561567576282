import React from 'react';
import { Form } from 'antd';
import TextArea from "antd/lib/input/TextArea";

const TrDescriptionEditor = ({ product, setProduct, setIsDirty, createChangeLog, productInitial }) => {

    const capitalize = (text) => {
        let words = text.split(" ");
        words = words.map(word => (word[0]?.toUpperCase() || "") + (word?.slice(1)?.toLowerCase() || ""));
        return words.join(" ");
    }

  return (
    <div className="description_box">
        <Form.Item label="Tr Description">
            <div className="inline-inputs">
                <Form.Item label="Fabric">
                    <TextArea
                    value={product?.description_details?.tr?.fabric}
                    onChange={(e) => {
                        setProduct({
                        ...product,
                        description_details: {
                            ...product.description_details,
                            tr : {
                            ...product?.description_details?.tr,  fabric: capitalize(e.target.value)
                            }
                        },
                        update_config: {...product?.update_config, "fabric": 'fixed'}
                        });
                        setIsDirty(true);
                        createChangeLog('Fabric', productInitial?.description_details?.tr?.fabric, capitalize(e.target.value));
                    }}
                    />
                </Form.Item>
                <Form.Item label="Model Measurement">
                    <TextArea
                    value={product?.description_details?.tr?.model_measurements}
                    onChange={(e) => {
                        setProduct({
                        ...product,
                        description_details: {
                            ...product.description_details,
                            tr : {
                            ...product?.description_details?.tr,  model_measurements: e.target.value
                            }
                        },
                        update_config: {...product?.update_config, "model_measurements": 'fixed'}
                        });
                        setIsDirty(true);
                        createChangeLog('Model Measurements', productInitial?.description_details?.tr?.model_measurements, e.target.value);
                    }}
                    />
                </Form.Item>
            </div>
            <div className="inline-inputs">
                <Form.Item label="Sample Size">
                    <TextArea
                    value={product?.description_details?.tr?.sample_size}
                    onChange={(e) => {
                        setProduct({
                        ...product,
                        description_details: {
                            ...product.description_details,
                            tr : {
                            ...product?.description_details?.tr,  sample_size: e.target.value
                            }
                        },
                        update_config: {...product?.update_config, "sample_size": 'fixed'}
                        });
                        setIsDirty(true);
                        createChangeLog('Sample Size', productInitial?.description_details?.tr?.sample_size, e.target.value);
                    }}
                    />
                </Form.Item>
                <Form.Item label="Product Measurement">
                    <TextArea
                    value={product?.description_details?.tr?.product_measurements}
                    onChange={(e) => {
                        setProduct({
                        ...product,
                        description_details: {
                            ...product.description_details,
                            tr : {
                            ...product?.description_details?.tr,  product_measurements: e.target.value
                            }
                        },
                        update_config: {...product?.update_config, "product_measurements": 'fixed'}
                        });
                        setIsDirty(true);
                        createChangeLog('Product Measurements', productInitial?.description_details?.tr?.product_measurements, e.target.value);
                    }}
                    />
                </Form.Item>
            </div>
        </Form.Item>

    </div>
  );
};

export default TrDescriptionEditor;
