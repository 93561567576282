import React from 'react';
import { Form, Select } from 'antd';

const StatusSelector = ({ product, setProduct, setIsDirty, addNew, createChangeLog, productInitial }) => {
  const handleStatusChange = (e) => {
    setProduct({
      ...product,
      status: e,
      update_config: {...product?.update_config, status: 'fixed'}
    });
    setIsDirty(true);
    createChangeLog('Status', productInitial?.status, e);
  };

  return (
    <Form.Item label="Status">
      <Select required onChange={handleStatusChange} value={product?.status}>
        <Select.Option value="Active">Active</Select.Option>
        <Select.Option value="Passive">Passive</Select.Option>
        <Select.Option value="Archived">Archived</Select.Option>
        { addNew && <Option value="Draft">Draft</Option>}
      </Select>
    </Form.Item>
  );
};

export default StatusSelector;
