import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button, Spin, notification } from "antd";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { fetchVendorsWithProject } from "../../../redux/vendorSlice";
import { updateStaticContent } from "../../../redux/staticContentSlice";

import "../EditStaticContent.scss";

const EditVendorsPageSorting = () => {
    const type = useParams()?.type;
    const dispatch = useDispatch();

    const { vendorsWithProject, status } = useSelector((state) => state.vendors);

    const getActiveVendorsWithSorting = () => {
        return vendorsWithProject
            ?.slice()
            ?.filter(v => v?.status === "Active")
            ?.sort((a, b) => a?.vendors_page_sorting - b?.vendors_page_sorting)
            ?.map(v => ({ id: v?._id, name: v?.name, vendors_page_sorting: v?.vendors_page_sorting }))
    }

    const [items, setItems] = useState(getActiveVendorsWithSorting());
    const [saveLoading, setSaveLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const hasUpdatedRef = useRef(false);

    useEffect(() => {
        setItems(getActiveVendorsWithSorting());
    }, [vendorsWithProject]);

    useEffect(() => {
        if (hasUpdatedRef.current) {
            dispatch(fetchVendorsWithProject());
        }
    }, [refresh]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
    
        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);
    
        setItems(newItems);
    };

    const handleSave = () => {
        setSaveLoading(true);

        const updateObject = items?.map((item, index) => ({ _id: item?.id, vendors_page_sorting: index+1 }));

        updateStaticContent(type, updateObject)
            .then(() => {
                notification['success']({
                    message: `Updated succesfully`
                });
                setSaveLoading(false);
                setRefresh(r => !r);
                hasUpdatedRef.current = true;
            })
            .catch(() => {
                notification['error']({
                    message: `Update failed!`
                });
                setSaveLoading(false);
            });
    };

    return (
        <div className="edit-static-content-container">
            <div className="edit-static-content-header">
                <h2>Edit Static Content - { type?.split('-')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ') } { status.fetchVendorsWithProject === "pending" && <Spin /> }</h2>
                <div className="edit-static-content-header-buttons">
                    <Button 
                        type="primary"
                        loading={saveLoading} 
                        onClick={handleSave} 
                        disabled={saveLoading || status.fetchVendorsWithProject === "pending"}
                    >
                        Save
                    </Button>
                </div>
            </div>

            <div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="items">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef} className="vendors-sorting-container">
                                {items.map(({ id, name }, index) => (
                                    <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={provided.draggableProps.style}
                                                className="vendors-sorting-item"
                                            >
                                                { index+1 }) { name }
                                            </li>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default EditVendorsPageSorting;