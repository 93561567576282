export const LONCA_INVOICE_ERRORS = {
    "VENDOR_ERROR": {
        message: "lonca_invoices.notifications.submission_fail",
        description: "lonca_invoices.notifications.vendor_error"
    },
    "INVOICE_NUMBER_ERROR": {
        message: "lonca_invoices.notifications.submission_fail",
        description: "lonca_invoices.notifications.invoice_number_error"
    },
    "DUPLICATE_INVOICE_NUMBER_ERROR": {
        message: "lonca_invoices.notifications.submission_fail",
        description: "lonca_invoices.notifications.duplicate_invoice_number_error"
    },
    "INVOICE_DATE_ERROR": {
        message: "lonca_invoices.notifications.submission_fail",
        description: "lonca_invoices.notifications.invoice_date_error"
    },
    "PRODUCT_ERROR": {
        message: "lonca_invoices.notifications.submission_fail",
        description: "lonca_invoices.notifications.invoice_product_error",
    },
    "SERVER_ERROR": {
        message: "lonca_invoices.notifications.submission_fail",
        description: "lonca_invoices.notifications.invoice_product_error",
    }
}

export const LONCA_INVOICE_SUCCESS_NOTIFS = {
    ADD: {
        message: "lonca_invoices.notifications.submission_success",
        description: "lonca_invoices.notifications.invoice_creation_success"
    },
    EDIT: {
        message: "lonca_invoices.notifications.submission_success",
        description: "lonca_invoices.notifications.invoice_update_success"
    }
}
