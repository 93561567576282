import { Button, DatePicker, Form, Input, Select, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import "./AddLoncaInvoice.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleSubmit, handleVendorChange, useGetInvoiceById, useGetInvoiceData, useGetNoInvoiceOrderProductsByVendor, useSetSubVendors, useGetVendors, useSetVendors, handleAutoInvoiceEnter, handleSubVendorChange } from "./functions/helper";
import { fetchVendorsWithProject } from "../../redux/vendorSlice";
import { createLoncaInvoice, fetchNoInvoiceOrderProductsByVendor, resetOrderProductsByVendor, updateLoncaInvoice } from "../../redux/loncaInvoiceSlice";
import InvoiceProducts from "./components/InvoiceProducts";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../components";
const { Option } = Select;

const AddLoncaInvoice = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    const { id } = useParams();
    const invoiceExists = !!id;
    const submitFunction = invoiceExists ? updateLoncaInvoice : createLoncaInvoice;
    const title = invoiceExists ? t("lonca_invoices.edit_invoice") : t("lonca_invoices.add_new_invoice");

    const { vendorsWithProject, status: vendorStatus } = useSelector(state => state.vendors);
    const { languagePath } = useSelector(state => state.user);
    const { orderProductsByVendor, invoice, status: loncaInvoiceStatus } = useSelector(state => state.loncaInvoice);

    const [ activeVendors, setActiveVendors ] = useState([]);
    const [ activeSubVendors, setActiveSubVendors ] = useState([]);

    const [ selectedVendor, setSelectedVendor ] = useState("");
    const [ selectedSubVendor, setSelectedSubVendor ] = useState("");
    const [ invoiceNumber, setInvoiceNumber ] = useState("");
    const [ invoiceDate, setInvoiceDate ] = useState(null);
    const [ parasutId, setParasutId ] = useState("");

    const [ selectedProducts, setSelectedProducts ] = useState([]);
    const [ invoiceProducts, setInvoiceProducts ] = useState([]);
    const [ hasMissingProduct, setHasMissingProduct ] = useState(false);

    const [ isSaveAvailable, setIsSaveAvailable ] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [ filter, setFilter ] = useState({
        page: 1,
        limit: 100,
        search: "",
        sort: "asc",
    });
    const [ invoiceProductsFilter, setInvoiceProductsFilter ] = useState({
        page: 1,
        limit: 100,
    });
    useGetVendors(vendorStatus.fetchVendorsWithProject, fetchVendorsWithProject, dispatch);
    useSetVendors(vendorStatus.fetchVendorsWithProject, vendorsWithProject, setActiveVendors);
    useSetSubVendors(vendorStatus.fetchVendorsWithProject, activeVendors, selectedVendor, setActiveSubVendors);
    
    /** Edit */
    useGetInvoiceById(id, invoiceExists, dispatch);
    useGetInvoiceData(
        setSelectedVendor,
        setSelectedSubVendor,
        setInvoiceNumber,
        setParasutId,
        setInvoiceDate,
        setSelectedProducts,
        setHasMissingProduct,
        loncaInvoiceStatus.invoice,
        invoiceExists,
        invoice
    );

    useEffect(() => {
        setInvoiceProducts(orderProductsByVendor);
        if(!invoiceExists) {
            setSelectedProducts([]);
        }
    }, [selectedVendor, orderProductsByVendor]);

    useEffect(() => {
        let hasAll = filter.noInvoice === null || filter.noInvoice === undefined;

        if(selectedVendor !== "") dispatch(fetchNoInvoiceOrderProductsByVendor({ selectedVendor, selectedSubVendor, hasAll: hasAll, filter: filter }));

    }, [filter, selectedVendor, selectedSubVendor]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if(searchText) setFilter({ ...filter, search: searchText });
        }, 1000);
    
        return () => {
          clearTimeout(timer);
        };
      }, [searchText]);

    const hasChangeFilter = (input, value) => {
        if(input === "search") {
            setSearchText(value);
        } else {
            setFilter({ ...filter, [input]: value });
        }
    }
    
    const specialVendors = ["EYYO ATELIER", "Ello", "Elisa", "Sobe Istanbul", "Black Fashion", "Topshow", "Ezgi Nisantasi", "MyBee", "Ebijuteri", "Janes", "My Fashion", "Mode Roy", "Cream Rouge", "Tuba", "Qustyle", "Etika", "CAPPITONE", "ESPADA", "Puane", "SOUL", "Reyon", "Polo Bonetta", "Holly Lolly", "First Angels", "Xhan", "Maxi Modena", "STEPS", "Mina Fashion", "My Jest Fashion", "BSL", "ARON Atelier", "Sortie Collection", "Radica Fashion", "Fiori", "la & vetta", "Axesoire", "Levure", "Juste", "SENSE", "Leina", "See More", "Yezra", "Mely", "Fame", "Bigdart", "XLove", "L&C",  "Allday", "Setre", "Ouz Fashion", "Kadriye Baştürk", "Hulya Keser", "Melotti", "LACARINO"]
    
    const isSpecialVendor = specialVendors.includes(selectedVendor)

    return (
        <div className="add-lonca-invoice-main-container">
            <div className="add-lonca-invoice-header">
                <h2>{ title }</h2>

                <div className="add-lonca-invoice-button-group">
                    {invoiceExists && isSpecialVendor && 
                        <Button
                            type="primary"
                            disabled={!isSaveAvailable}
                            onClick={() => handleAutoInvoiceEnter(id, setIsSaveAvailable, t)}
                        >
                            {t("buttons.Auto Invoice Enter")}
                        </Button>
                    }
                    <Button
                        type="primary"
                        disabled={!isSaveAvailable}
                        onClick={() => handleSubmit(selectedProducts, selectedVendor, selectedSubVendor, invoiceNumber, parasutId, invoiceDate, hasMissingProduct, id, submitFunction, navigate, setIsSaveAvailable, invoiceExists, t)}
                    >
                        {t("buttons.Save")}
                    </Button>
                    <Button
                        type="secondary"
                        onClick={() => {
                            dispatch(resetOrderProductsByVendor());
                            navigate("../lonca-invoices");
                        }} 
                    >
                        { t("buttons.Cancel") }
                    </Button>
                </div>
            </div>

            <div className="add-lonca-invoice-input-container">
                <div className="add-lonca-invoice-input-container-above">
                <Form.Item className="add-lonca-invoice-form-item" label={t("home.vendor")}>
                    <Select 
                        placeholder={t("lonca_invoices.select_vendor")} 
                        onChange={(e) => handleVendorChange(e, setSelectedSubVendor, setSelectedVendor)}
                        showSearch
                        disabled={invoiceExists}
                        value={selectedVendor === "" ? undefined : selectedVendor}
                    >
                        {
                            activeVendors?.map((vendor) => {
                                return (
                                    <Option key={vendor?._id} value={vendor?.name}>
                                        {vendor?.name}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </Form.Item>
                {<Form.Item className="add-lonca-invoice-form-item" label={t("home.Sub Vendor")}>
                    <Select 
                        placeholder={t("lonca_invoices.select_sub_vendor")} 
                        onChange={(e) => handleSubVendorChange(e, setSelectedSubVendor, setSelectedVendor, activeVendors)}
                        showSearch
                        disabled={invoiceExists}
                        value={selectedSubVendor === "" ? undefined : selectedSubVendor}
                    >
                        {
                            activeSubVendors?.map((vendor) => {
                                return (
                                    <Option key={vendor} value={vendor}>    
                                        {vendor}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </Form.Item>}
                <Form.Item className="add-lonca-invoice-form-item" label={t("lonca_invoices.invoice_number")}>
                    <Input
                        placeholder={t("lonca_invoices.enter_invoice_number")}
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                </Form.Item>
                <Form.Item className="add-lonca-invoice-form-item" label={t("lonca_invoices.invoice_date")}>
                    <DatePicker
                        placeholder={t("lonca_invoices.enter_invoice_date")}
                        value={invoiceDate}
                        onChange={setInvoiceDate}
                    />
                </Form.Item>
                </div>
                <div className="add-lonca-invoice-input-container-below">
                    <div className="add-lonca-invoice-input-parasut-id">
                        <Form.Item className="add-lonca-invoice-form-item" label={t("lonca_invoices.parasut_id")}>
                            <Input
                                placeholder={t("lonca_invoices.enter_parasut_id")}
                                value={parasutId}
                                onChange={(e) => setParasutId(e.target.value)}
                            />
                        </Form.Item>
                        <Button 
                            disabled={!parasutId}
                            type="primary"
                            onClick={() => window.open(`https://uygulama.parasut.com/496743/fis-faturalar/${parasutId}`, "_blank")}
                        >
                            {t("lonca_invoices.redirect_to_parasut")}
                        </Button>
                    </div>
                {hasMissingProduct &&
                    <div className="add-lonca-invoice-unmatched-warning">
                        {t("lonca_invoices.has_missing_product")}
                    </div>
                }
                <Form.Item className="add-lonca-invoice-form-item-checkbox" label={t("lonca_invoices.has_missing_product_label")}>
                    <Checkbox 
                        checked={hasMissingProduct} 
                        onChange={(e) => setHasMissingProduct(e.target.checked)}>
                    </Checkbox> 
                </Form.Item>
                </div>
            </div>
            
            <div className="add-lonca-invoice-products-container">
                {/** Invoiced Products */}
                <InvoiceProducts 
                    languagePath={languagePath}
                    filter={invoiceProductsFilter}
                    setFilter={setInvoiceProductsFilter}
                    products={selectedProducts} 
                    type={"invoice"} 
                    addFunction={setInvoiceProducts} 
                    removeFunction={setSelectedProducts}
                    title={t("lonca_invoices.invoice_products")}
                    isEmpty={!selectedProducts || selectedProducts?.length === 0}
                    emptyText={t("lonca_invoices.invoice_products_empty_text")}
                />

                {
                    selectedProducts?.length > 0 &&
                    <Pagination filter={invoiceProductsFilter} setFilter={setInvoiceProductsFilter} />
                }

                {/** No Invoice Products */}
                <InvoiceProducts 
                    languagePath={languagePath}
                    filter={filter}
                    hasChangeFilter={hasChangeFilter}
                    products={invoiceProducts} 
                    type={"no_invoice"} 
                    addFunction={setSelectedProducts} 
                    removeFunction={setInvoiceProducts}
                    title={t("lonca_invoices.no_invoice_products")}
                    isEmpty={!invoiceProducts || invoiceProducts?.length === 0}
                    emptyText={t("lonca_invoices.no_invoice_products_empty_text")}
                />

                {
                    invoiceProducts?.length > 0 &&
                    <Pagination filter={filter} setFilter={setFilter} />
                }
            </div>
        </div>
    );
}

export default AddLoncaInvoice;