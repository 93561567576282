import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';

const VendorSelector = ({ vendors, product, setProduct, setIsDirty, isAllowed }) => {

    const handleVendorChange = (e) => {
        const vendor = vendors.find(temp => temp?._id === e)
        setProduct({
        ...product,
        vendor,
        });
        setIsDirty(true);
    };

    return (
        <Form.Item name="vendor" label="Vendor">
        <Select
            showSearch
            optionFilterProp="children"
            required
            onChange={handleVendorChange}
            disabled={!isAllowed}
        >
            {vendors
            ?.slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((vendor, index) => (
                <Select.Option key={'vendor-select' + index} value={vendor?._id}>
                {vendor?.name}
                </Select.Option>
            ))}
        </Select>
        </Form.Item>
    );
};

export default VendorSelector;
