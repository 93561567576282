import { useEffect } from "react"
import { notification } from "antd";
import { LONCA_INVOICE_ERRORS, LONCA_INVOICE_SUCCESS_NOTIFS } from "../constants";
import { fetchAllLoncaInvoices, fetchInvoiceById, autoInvoiceEnter } from "../../../redux/loncaInvoiceSlice";
import moment from "moment";

export const useGetVendors = (vendorStatus, fetchVendors, dispatch) => {
    useEffect(() => {
        if(vendorStatus === "idle") {
            dispatch(fetchVendors());
        }
    }, [vendorStatus, fetchVendors, dispatch]);
}

export const useSetVendors = (vendorStatus, vendors, setActiveVendors) => {
    useEffect(() => {
        if(vendorStatus === "succeeded") {
            const activeVendors = vendors?.filter((v) => v?.status === "Active");
            const activeAndSortedVendors = activeVendors?.sort((a, b) => a?.name.localeCompare(b?.name));
            setActiveVendors(activeAndSortedVendors);
        }
    }, [vendorStatus, vendors, setActiveVendors]);
}

export const useSetSubVendors = (vendorStatus, vendors, selectedVendor, setSubVendors) => {
    useEffect(() => {
        if(vendorStatus === "succeeded" && vendors?.length > 0) {
            let allSubVendors = [];
            vendors?.map(vendor => {
                allSubVendors = allSubVendors.concat(vendor?.sub_vendors?.map((sub_vendor) => sub_vendor?.name) || []);
            });
            setSubVendors(allSubVendors);

            /** Old Logic */
            /* const vendor = vendors?.find((vendor) => vendor?.name === selectedVendor);
            if(vendor?.sub_vendors?.length > 0) {
                setSubVendors(vendor?.sub_vendors?.map((sub_vendor) => sub_vendor?.name));
            } else {
                setSubVendors([]);
            } */
        }
    }, [vendorStatus, vendors, selectedVendor, setSubVendors])
}

export const useGetNoInvoiceOrderProductsByVendor = (filter, fetchNoInvoiceOrderProductsByVendor, selectedVendor, selectedSubVendor, dispatch) => {
    useEffect(() => {
        if(selectedVendor !== "") {
            dispatch(fetchNoInvoiceOrderProductsByVendor({ selectedVendor, selectedSubVendor, hasAll: true, filter: filter }));
        }
    }, [fetchNoInvoiceOrderProductsByVendor, selectedVendor, selectedSubVendor, dispatch]);
}

export const useGetInvoicesByVendor = (filter, dispatch) => {
    useEffect(() => {
        dispatch(fetchAllLoncaInvoices({ ...filter }))
    }, [filter, dispatch]);
}

export const handleVendorChange = (selectedVendorValue, setSelectedSubVendor, setSelectedVendor) => {
    setSelectedVendor(selectedVendorValue);
    setSelectedSubVendor("");
}

export const handleSubVendorChange = (selectedSubVendorValue, setSelectedSubVendor, setSelectedVendor, vendors) => {
    setSelectedSubVendor(selectedSubVendorValue);
    const parentVendor = findParentVendor(selectedSubVendorValue, vendors);
    if(parentVendor) {
        setSelectedVendor(parentVendor);
    }
}

const findParentVendor = (selectedSubVendor, vendors) => {
    const vendor = vendors?.find((v) => {
        const subVendorNames = v?.sub_vendors?.map(subVendor => subVendor?.name);
        return subVendorNames?.includes(selectedSubVendor);
    });

    return vendor?.name;
}

export const handleSubmit = async (
    selectedProducts, 
    selectedVendor, 
    selectedSubVendor, 
    invoiceNumber, 
    parasutId,
    invoiceDate,
    hasMissingProduct,
    _id,
    handleFunction, 
    navigate,
    setIsSaveAvailable,
    invoiceExists,
    t
) => {
    const successKey = invoiceExists ? "EDIT" : "ADD";
    setIsSaveAvailable(false);

    const errorKey = await handleErrors({
        selectedVendor: selectedVendor, 
        invoiceNumber: invoiceNumber, 
        invoiceDate: invoiceDate,
    });

    if(errorKey) {
        notification.error({
            message: t(LONCA_INVOICE_ERRORS[errorKey].message),
            description: t(LONCA_INVOICE_ERRORS[errorKey].description),
            duration: 2.5
        });
        setIsSaveAvailable(true);
    } else {
        try {
            await handleFunction({
                _id: _id,
                selectedProducts: selectedProducts,
                selectedVendor: selectedVendor,
                selectedSubVendor: selectedSubVendor,
                invoiceNumber: invoiceNumber,
                invoiceDate: invoiceDate,
                hasMissingProduct: hasMissingProduct,
                parasutId: parasutId
            }).then(data => {
                if(data?.error) {
                    notification.error({
                        message: t(LONCA_INVOICE_ERRORS[data?.error].message),
                        description: t(LONCA_INVOICE_ERRORS[data?.error].description)
                    });
                    setIsSaveAvailable(true);
                } else {
                    notification.success({
                        message: t(LONCA_INVOICE_SUCCESS_NOTIFS[successKey].message),
                        description: t(LONCA_INVOICE_SUCCESS_NOTIFS[successKey].description)
                    });
                
                    setTimeout(() => {
                        navigate("../lonca-invoices");
                    }, 1500);
                    setIsSaveAvailable(true);
                    }
                });
        } catch (error) {
            if (error?.response?.data?.error === "DUPLICATE_INVOICE_NUMBER_ERROR") {
                notification.error({
                    message: t(LONCA_INVOICE_ERRORS.DUPLICATE_INVOICE_NUMBER_ERROR.message),
                    description: t(LONCA_INVOICE_ERRORS.DUPLICATE_INVOICE_NUMBER_ERROR.description)
                });
            } else {
                notification.error({
                    message: t(LONCA_INVOICE_ERRORS.SERVER_ERROR.message),
                    description: t(LONCA_INVOICE_ERRORS.SERVER_ERROR.description)
                });
            }
            setIsSaveAvailable(true);
        }
    }
}

export const useGetInvoiceById = (_id, invoiceExists, dispatch) => {
    useEffect(() => {
        try {
            if(invoiceExists) {
                dispatch(fetchInvoiceById({
                    _id: _id
                }));
            }
        } catch (error) {
            console.log(error)
        }
    }, [_id, invoiceExists, dispatch])
}

export const useGetInvoiceData = (
    setSelectedVendor,
    setSelectedSubVendor,
    setInvoiceNumber,
    setParasutId,
    setInvoiceDate,
    setSelectedProducts,
    setHasMissingProduct,
    invoiceStatus,
    invoiceExists,
    invoice
) => {
    useEffect(() => {
        if(invoiceExists && invoiceStatus === "fulfilled") {
            setSelectedVendor(invoice?.vendor_name);
            setSelectedSubVendor(invoice?.sub_vendor_name);
            setInvoiceNumber(invoice?.invoice_number);
            setInvoiceDate(moment(invoice?.invoice_date));
            setSelectedProducts(invoice?.invoice_items);
            setHasMissingProduct(invoice?.has_missing_product),
            setParasutId(invoice?.parasut_id)
        }
    }, [setSelectedVendor,
        setSelectedSubVendor,
        setInvoiceNumber,
        setParasutId,
        setInvoiceDate,
        setSelectedProducts,
        invoiceStatus,
        invoiceExists,
        invoice,
        setHasMissingProduct
    ]);
}

export const handleAutoInvoiceEnter = async (
    _id,
    setIsSaveAvailable,
    t
) => {
    setIsSaveAvailable(false);

    try {
        await autoInvoiceEnter({
            _id,
        });

        notification['success']({
            message: 'Auto invoice enter successful',
        });

        setTimeout(() => {
            window.location.reload();
        }, 1000);

    } catch (error) {
        notification['error']({
            message:  error?.response?.data?.error || t('lonca_invoices.notifications.server_error')
        });
        setIsSaveAvailable(true);
    }
    
}

const handleErrors = async ({
    selectedVendor: selectedVendor, 
    invoiceNumber: invoiceNumber, 
    invoiceDate: invoiceDate,
}) => {
    let errorKey;

    if (!selectedVendor) {
        errorKey = "VENDOR_ERROR"
    } else if (!invoiceNumber) {
        errorKey = "INVOICE_NUMBER_ERROR"
    } else if (!invoiceDate) {
        errorKey = "INVOICE_DATE_ERROR"
    }

    return errorKey;
}