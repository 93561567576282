import React from "react";
import "./InvoiceProducts.scss";
import InvoiceProduct from "./InvoiceProduct";
import { Select, Input, DatePicker } from "antd";
import moment from "moment";
import tr_locale from "antd/lib/locale-provider/tr_TR";
const { RangePicker } = DatePicker;

const InvoiceProducts = ({
    products,
    type,
    addFunction,
    removeFunction,
    title,
    isEmpty,
    emptyText,
    filter,
    hasChangeFilter,
    languagePath
}) => {
    const buttonName = type === "invoice" ? "buttons.Remove" : type === "no_invoice" ? "buttons.Add" : "";
    const buttonType = type === "invoice" ? "secondary" : type === "no_invoice" ? "primary" : "";

    return (
        <div className="invoice-products-main-container">
            <div className="invoice-products-header">
                <h3 className="invoice-products-title">{ title }</h3>
                <span className="invoice-products-number-of-products">Number of products: { products?.length }</span>
                {
                    type === "no_invoice" &&
                    <div className="invoice-products-header-filter-container">
                        <div className="invoice-products-header-filter-container-inner">
                            <div className="invoice-products-filter-input">
                                <span className="invoice-products-filter-label">Search</span>
                                <Input className='all-w-100 all-search-bar' placeholder='Search' onChange={(e) => hasChangeFilter("search", e.target.value)} />
                            </div>
                            <div className="invoice-products-filter-select">
                                <span className="invoice-products-filter-label">No Invoice</span>
                                <Select
                                options={[
                                    { value: null, label: 'All' },
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ]}
                                defaultValue={null}
                                value={filter.noInvoice}
                                placeholder="No Invoice"
                                onChange={(value) => hasChangeFilter("noInvoice", value)}
                                />
                            </div>
                                <div className="invoice-products-filter-input">
                                    <span className="invoice-products-filter-label">Date</span>
                                    <RangePicker
                                        locale={languagePath === "" ? tr_locale : null}
                                        value={[moment(filter?.date?.start || new Date()), moment(filter?.date?.end || new Date())]}
                                        onChange={([start, end]) => {
                                            hasChangeFilter("date", { start: new Date(start._d), end: new Date(end._d) })
                                        }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="invoice-products-container">
                {
                    products?.map((invoiceProduct) => {
                        return (
                            <InvoiceProduct 
                                invoiceProduct={invoiceProduct} 
                                type={type} 
                                addFunction={addFunction}
                                removeFunction={removeFunction}
                                buttonName={buttonName}
                                buttonType={buttonType}
                            />
                        )
                    })
                }
                {
                    isEmpty && <strong>{ emptyText }</strong>
                }
            </div>
        </div>
    )
}

export default InvoiceProducts;