import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, notification, InputNumber, Checkbox, Spin } from 'antd';
import "./EditCoupon.css"
import { fetchUpdate, fetchCreate } from "../../redux/couponSlice";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";
import { fetchCustomers } from "../../redux/customerSlice";

import { loncaRound } from "../../helpers";
import { fetchSearchBarResults, searchBarItemsSelector } from "../../redux/searchSlice";
import { debounce } from "lodash";

const EditCoupon = ({ coupon, addNew, setCoupon, setShowForm, customer }) => {

	const { vendorsWithProject: vendorsMain } = useSelector((state) => state.vendors);
	const { Option } = Select;
	const { RangePicker } = DatePicker;
	const [form] = Form.useForm();
	const [currencies, setCurrencies] = useState([]);
	const [currency, setCurrency] = useState();
	const [showDate, setShowDate] = useState(coupon?.start_date && coupon?.end_date);

	const [vendors, setVendors] = useState(vendorsMain.filter((i) => i.status === "Active"));
	const [customers, setCustomers] = useState([]);
	const searchBarItems = useSelector(searchBarItemsSelector);
	const searchType = "customer"
	const dispatch = useDispatch();
	const [localSearchText, setLocalSearchText] = useState('');

	useEffect(() => {
		if(!customer){
			fetchCustomers('', 'all_list')
			.then(res => setCustomers(res?.data))
			.catch(err => console.log(err))
		}
	}, [])

	useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			setCurrencies(res?.data);
			setCurrency(res?.data?.find(c => (c?.abbreviation === coupon?.currency)) || res?.data?.find(c => (c?.abbreviation === "USD")))
		  })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const debouncedFetchResults = useCallback(
		debounce((searchText) => {
		  if (searchText) dispatch(fetchSearchBarResults({ searchText, searchType }));
		}, 200),
		[dispatch, searchType],
	  );

	useEffect(() => {
		if (localSearchText.length === 0) return;
		debouncedFetchResults(localSearchText);
	  }, [localSearchText, debouncedFetchResults]);
	  
	useEffect(() => {
		if (searchBarItems.length === 0) return;
		setCustomers(searchBarItems);
	  }, [searchBarItems]);
	  
	const handleSubmit = () => {
		let updatedCoupon = { 
			...coupon, 
			discount_amount: coupon.discount_type === "Absolute" ? coupon.discount_amount : loncaRound(coupon.discount_amount / 100),
		}

		if(!showDate) {
			updatedCoupon.start_date = null;
			updatedCoupon.end_date = null;
		}

		if(coupon?.return_discount === null) {
			notification['error']({
				message: 'You must specify a return discount value!',
			});
			return;
		}

		if(!addNew) fetchUpdate(coupon._id, {...updatedCoupon, special_customer: coupon?.special_customer === "none" ? null : coupon?.special_customer, vendor: coupon?.vendor === "none" ? null : coupon?.vendor})
			.then((res) => {
				notification['success']({
					message: 'Coupon saved successfully',
				});
				if(customer) {
					setShowForm(false);
				}
			})
			.catch((err) => {
				console.log(err)
				notification['error']({
					message: 'Coupon couldn\'t saved successfully',
				});
			});
			
		if(addNew) {
			fetchCreate({...updatedCoupon, special_customer: coupon?.special_customer === "none" ? null : coupon?.special_customer, vendor: coupon?.vendor === "none" ? null : coupon?.vendor})
				.then((res) => {
					notification['success']({
						message: 'Coupon created successfully',
					});
					if(customer) {
						setShowForm(false);
					}
				})
				.catch((err) => {
					console.log(err)
					notification['error']({
						message: 'Coupon couldn\'t created successfully',
					});
				});
		}
	}

	return(
		<div className="container">
			<div className="coupon-details">
				<Form
					form={form}
					className="coupon-form"
					onFinish={handleSubmit}
					layout="vertical"
					initialValues={{
						status: coupon?.status,
						discount_type: coupon?.discount_type,
						code: coupon?.code,
						description: coupon?.description,
						amount: coupon?.discount_type === "Absolute" ? coupon?.discount_amount : coupon?.discount_amount * 100,
						date: [moment(coupon?.start_date || new Date()), moment(coupon?.end_date || new Date())],
						currency: coupon?.currency || "USD",
						show_date: coupon?.start_date && coupon?.end_date,
						special_customer: customer ? customer : customers?.find(c => c?._id?.toString() === coupon?.special_customer?.toString()) || null
					}}
				>
					<div className="header-save">
						<h2 className="coupon-header">{coupon?.code ? coupon.code : "Code"}</h2>
						<div>
							<Button type="primary" onClick={handleSubmit} style={{ marginRight: "10px" }}>
								Save
							</Button>
							<Button type="secondary" onClick={() => {
								if(!customer || !addNew){
									setCoupon({});
								}
								setShowForm(false);
							}}>
								Cancel
							</Button>
						</div>
					</div>
					<div className="form-top">
						<div className="form-left">
							<Form.Item label="Code" name="code">
								<Input 
									value={coupon?.code} 
									onChange={(e) => {
										setCoupon({...coupon, code: e.target.value.trim().replaceAll(" ", "").toUpperCase()})
									}}
								/>
							</Form.Item>
							<Form.Item label="Description" name="description">
								<Input 
									value={coupon?.description} 
									onChange={(e) => {
										setCoupon({...coupon, description: e.target.value})
									}}
								/>
							</Form.Item>
							<Form.Item label="Amount" name="amount">
								<InputNumber
									prefix={coupon?.discount_type === "Absolute" ? currency?.symbol : "%" }
									value={coupon?.discount_amount === 0 ? "" : coupon?.discount_amount} 
									onChange={(e) => {
										setCoupon({
											...coupon, 
											discount_amount: loncaRound(e)
										})
									}}
									style={{ width: "100%" }}
								/>
							</Form.Item>
							<Form.Item label="Vendor">
								<Select
									showSearch
									optionFilterProp="children"
									required
									value={coupon?.vendor}
									onChange={(e) => {
										setCoupon({
											...coupon,
											vendor: e
										});
									}}
								>
									<Option key={'vendor-select'} value="none">
										None
									</Option>
									{vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
										<Option key={'vendor-select' + index} value={vendor?._id}>
											{vendor?.name}
										</Option>
									))}
								</Select>
							</Form.Item>
							{!customer && <Form.Item label="Customer" loading>

										<Select
										showSearch
										optionFilterProp="children"
										required
										filterOption={(input, option) => {
											const optionValue = option.props.value;
											const optionKey = option.props.key;
											return (
											optionValue?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											optionKey?.indexOf(input.toLowerCase()) >= 0
											);
										}}
										value={coupon?.special_customer}
										onSearch={(input) => {
											// Handle search logic here
											setLocalSearchText(input);

										}}
										onChange={(e) => {
											setCoupon({
											...coupon,
											special_customer: e,
											});
										}}
										>
										<Option key={'customer-select'} value="none">
											None || Type to see customers
										</Option>
										{customers?.slice().sort((a, b) => a?.firstName?.localeCompare(b?.firstName)).map((customer, index) => (
											<Option key={customer?.email} value={customer?._id}>
												{customer?.firstName} {customer?.lastName} {'-'} {customer?.email}
											</Option>
											))}
										</Select>

									</Form.Item>}


							<p className="date-checkbox">
								<Checkbox checked={showDate} onChange={(e) => setShowDate(e.target.checked)}>
									I want to specify date interval
								</Checkbox>
							</p>

							{ showDate ? <Form.Item label="Start Date - End Date" name="date">
								<RangePicker
									showTime
									value={[coupon?.start_date || new Date(), coupon?.end_date || new Date()]}
									onChange={([start, end]) => {
										setCoupon({...coupon, start_date: new Date(start._d), end_date: new Date(end._d)})
									}}
									style={{ width: "100%" }}
								/>
							</Form.Item> : null}

							<Form.Item label="Return Discount">
								<Select
									value={coupon?.return_discount}
									onChange={(e) => {
										setCoupon({
											...coupon,
											return_discount: e
										});
									}}
								>
									<Option key="null" value={null}>None</Option>
									<Option key="true" value={true}>Yes</Option>
									<Option key="false" value={false}>No</Option>
								</Select>
							</Form.Item>

							<Form.Item label="Promotion">
								<Select
									value={coupon?.promotion}
									onChange={(e) => {
										setCoupon({
											...coupon,
											promotion: e
										});
									}}
								>
									<Option key="true" value={true}>Yes</Option>
									<Option key="false" value={false}>No</Option>
								</Select>
							</Form.Item>

							<Form.Item label="Reusability">
								<Select
									value={coupon?.is_reusable}
									onChange={(e) => {
										setCoupon({
											...coupon,
											is_reusable: e
										});
									}}
								>
									<Option key="true" value={true}>Yes</Option>
									<Option key="false" value={false}>No</Option>
								</Select>
							</Form.Item>
						</div>
						<div className="form-right">
							<Form.Item name="status" label="Status">
								<Select
									onChange={(e) => {
										setCoupon({
											...coupon, 
											status: e
										})
									}}
								>
									<Option value="Active">Active</Option>
									<Option value="Passive">Passive</Option>
									<Option value="Archived">Archived</Option>
								</Select>
							</Form.Item>
							<Form.Item name="discount_type" label="Discount Type">
								<Select
									onChange={(e) => {
										setCoupon({
											...coupon, 
											discount_type: e
										})
									}}
								>
									<Option value="Percentage">Percentage</Option>
									<Option value="Absolute">Absolute</Option>
								</Select>
							</Form.Item>

							{ coupon?.discount_type === "Absolute" ? 
							<Form.Item name="currency" label="Currency">
								<Select
									onChange={(e) => {
										setCoupon({
											...coupon, 
											currency: e
										})
										setCurrency(currencies?.find(c => c?.abbreviation === e))
									}}
								>
									{currencies?.map(c => c?.abbreviation)?.map(currency => (
										<Option value={currency}>{currency}</Option>
									))}
								</Select>
							</Form.Item> : null
							}
						</div>
					</div>
				</Form>
			</div>
		</div>
	)
};

export default EditCoupon;