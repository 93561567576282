import React, { useState, useEffect, useRef } from "react"
import { Input, Select, Spin, notification, Button } from 'antd';
import { Pagination } from '../../components';
import axios from "axios";
import {
  loncaRound,
  loncaFormatDate,
} from '../../helpers';
import {
  ALL_LOGISTICS_ORDER_STATUSES,
  ADMIN_ACTIVE_ORDER_STATUSES,
} from '../../constants'
import { fetchOrderAggregate, updateCartItems } from '../../redux/logisticsOrderSlice';
import "./AllLogisticOrders.css";
import 'moment/locale/tr';
import { t } from "i18next";
import { DownloadOutlined } from '@ant-design/icons';
import usePrompt from "../../hooks/usePrompt";

const AllLogisticOrders = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('{"createdAt":-1}');
  const [pagination, setPagination] = useState({ page: 1, limit: 100 });
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [statusFilter, setStatusfFilter] = useState('');
  const [invoiceFilter, setInvoiceFilter] = useState('');
  const [changedItems, setChangedItems] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState(ADMIN_ACTIVE_ORDER_STATUSES);

  const { Option } = Select;
  const allOrderStatusWithTranslations = ALL_LOGISTICS_ORDER_STATUSES?.map(order_status => {
    return {
      label: t(order_status.label),
      value: order_status.value
    }
  });

  usePrompt("You have unsaved changes, do you want to leave?", isDirty);

  const handleChangeCartItem = ({ item, field, value }) => {
    const existingItem = cartItems?.find(i => i?.item_id === item?.item_id);
    let newItem = {
      ...existingItem,
      [field]: value
    };

    cartItems[cartItems?.findIndex(i => i?.item_id === item?.item_id)] = newItem;
    setCartItems(cartItems);

    // find changed item for same orderId
    let relatedOrder = changedItems?.find(changedItem => changedItem?.orderId?.toString() === newItem?.unique_order_id?.toString());
    // find item of changed item for same orderId and same cart_item id
    let relatedCartItem = null;

    if (relatedOrder) {
      relatedCartItem = relatedOrder?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === newItem?.item_id?.toString())
    }

    if (relatedOrder) {
      if (relatedCartItem) {
        // if related cart item exists, find it and replace with the new version
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems?.filter(cart_item => cart_item?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
              {
                item: {
                  _id: newItem?.item_id,
                  ...relatedCartItem?.item,
                  [field]: value
                }
              }
            ]
          }
        ])
      } else {
        // if relatedOrder exists, add updated item to its cartItems
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems,
              {
                item: {
                  _id: newItem?.item_id,
                  [field]: value
                },
                type: "update"
              }
            ]
          }
        ])
      }
    } else {
      // if relatedOrder doesn't exists, add a new changed item with deleted item
      setChangedItems(items => [
        ...items, 
        { 
          orderId: newItem?.unique_order_id,
          cartItems: [{ 
            item: {
              _id: newItem?.item_id,
              [field]: value
            }, 
            type: "update" 
          }]
        }
      ]);
    }
    setIsDirty(true);
  }

  const handleSave = () => {

    setSaveLoading(true);

    updateCartItems(changedItems)
    .then((res) => {
      setSaveLoading(false);
      setIsDirty(false);
      notification['success']({
        message: 'Changes saved successfully',
      });

    })
    .catch((err) => {
      setSaveLoading(false);
      console.log(err)
      notification['error']({
        message: 'Changes couldn\'t saved successfully',
      });
    });
  }

  const handleRefresh = () => {
    setChangedItems([]);
    setCartItems([]);
    setIsDirty(false);
    setRefresh(r => !r);
  }

  const calculatePricesWithCurrency = (value, item) => {
    return !!value ? loncaRound(value * item?.rate_usd_selling) : 0;
  }

  const onOrderStatusFilterChange = (checkedValues) => {
    if(checkedValues?.includes("all")) {
        const allOrderStatuses = allOrderStatusWithTranslations?.map(option => option?.value);
        setSelectedOrderStatuses(allOrderStatuses);
        setStatusfFilter(allOrderStatuses?.filter(os => os !== "all"));
    } else if(selectedOrderStatuses?.includes("all")) {
        setSelectedOrderStatuses([]);
        setStatusfFilter([]);
    } else {
        setSelectedOrderStatuses(checkedValues);
        setStatusfFilter(checkedValues);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchText);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);
    

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    fetchOrderAggregate({
      filter: [
        statusFilter,
        invoiceFilter,
        searchText,
        sort,
        pagination
      ],
      mode: "all_orders",
      cancelToken: source.token
    })
      .then((res) => {
        setCartItems(res.data);
        setChangedItems([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });

    return () => {
      source.cancel('Operation canceled by the user.');
    }

  }, [pagination?.page, pagination?.limit, sort, statusFilter, debouncedSearch, invoiceFilter, refresh]);

  return (
    <div>
      <div>
      <div className='top-bar'>
          <h2>All Logistic Orders</h2>
          <div className='top-bar'>
            <div className='top-btns'>
              <Button className='save-btn' loading={saveLoading} type='primary' onClick={() => handleSave()}>Save</Button>
              <Button className='discard-btn' type='primary' onClick={() => {
                if (window.confirm('Do you want do discard all changes?')) handleRefresh();
              }
              }>Discard Changes</Button>
            </div>
          </div>
        </div>

        

        <div className='all-form-bar'>
          <div className="filter-container all-form-left-container">
            <span className="all-form-label">Number of orders: {cartItems?.length}</span>
            <Input className='all-w-100 all-search-bar' placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />
          </div>
          <div className="all-form-right-container">
          <div className="filter-container">
          <span className="all-form-label">No Invoice</span>
            <Select style={{ width: "90%" }} defaultValue={''} onChange={setInvoiceFilter}>
              <Option key='all' value=''>Any</Option>
              <Option key='entered-with-invoice' value={true}>Yes</Option>
              <Option key='entered-without-invoice' value={false}>No</Option>
            </Select>
          </div>
            <div className="filter-container" >
            <span className="all-form-label">Order Status</span>
              <Select
                mode="multiple"
                allowClear
                placeholder="Select Order Status"
                value={selectedOrderStatuses}
                onChange={onOrderStatusFilterChange}
                options={allOrderStatusWithTranslations}
                maxTagCount="responsive"
                showArrow
                className="all-w-100"
              />
            </div>
            <div className="filter-container">
            <span className="all-form-label">Sort</span>
              <Select placeholder='Sort' style={{ width: "90%" }} defaultValue={sort} onChange={setSort}>
                <Option value='{"createdAt":-1}'>{t('fields.edit_order.Created Date')}: {t('sort.New to Old')}</Option>
                <Option value='{"createdAt":1}'>{t('fields.edit_order.Created Date')}: {t('sort.Old to New')}</Option>
                <Option value='{"product_cost_payment_at":-1}'>{t('fields.order_status.Payment')}: {t('sort.New to Old')}</Option>
                <Option value='{"product_cost_payment_at":1}'>{t('fields.order_status.Payment')}: {t('sort.Old to New')}</Option>
                <Option value='{"delivery_date":-1}'>{t('fields.order_status.Delivery')}: {t('sort.New to Old')}</Option>
                <Option value='{"delivery_date":1}'>{t('fields.order_status.Delivery')}: {t('sort.Old to New')}</Option>
                <Option value='{"shipment_date":-1}'>{t('fields.order_status.Shipment')}: {t('sort.New to Old')}</Option>
                <Option value='{"shipment_date":1}'>{t('fields.order_status.Shipment')}: {t('sort.Old to New')}</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className='product-table'>
        {loading && <Spin tip={"Loading Products..."} />}

        {cartItems?.map((item, index) => {

          return (
            <div className="product-container all-product-container" key={item.item_id + index}>
              <div className="all-item-info">
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Customer</span>
                    <input className='ant-input' value={item?.customer_name} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unique Customer ID</span>
                    <Input disabled value={item?.unique_customer_id}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unique Order ID</span>
                    <Input disabled value={item?.unique_order_id}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Order Date</span>
                    <Input disabled value={loncaFormatDate(item?.createdAt)}
                    />
                  </div>
                </div>
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Vendor</span>
                    <Input disabled value={item?.vendor_name} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Vendor Billing Name</span>
                    <Input disabled value={item?.vendor_billing_name} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Name EN</span>
                    <Input disabled value={item?.product_name_en} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Name TR</span>
                    <Input disabled value={item?.product_name_tr} />
                  </div>
                </div>
                <div className="all-info-row all-info-row-price">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Quantity</span>
                    <Input disabled value={item?.quantity}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price</span>
                    <input className='ant-input' value={`$${loncaRound(item?.price)}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price(₺)</span>
                    <input className='ant-input' value={`₺${loncaRound(item.price_try)}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price(€)</span>
                    <input className='ant-input' value={`€${loncaRound(item?.price_eur)}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Total Price</span>
                    <input className='ant-input' value={`$${loncaRound(item?.quantity * item?.price)} - €${calculatePricesWithCurrency(item?.quantity * item?.price, item)}`} disabled />
                  </div>
                </div>
                <div className="all-info-row all-info-row-price">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Status</span>
                    <input className='ant-input' value={item?.status} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Type</span>
                    <input className='ant-input' value={item?.product_type} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Notes</span>
                    <textarea className='ant-input' value={item?.note} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span>No Invoice</span>
                    <input style={{ "marginTop": "5px" }} type="checkbox" value={item?.entered_without_invoice} checked={item?.entered_without_invoice}
                      onChange={(e) => {
                        handleChangeCartItem({ item, field: 'entered_without_invoice', value: e.target.checked })
                      }} />
                  </div>
                  {item?.invoice_link && 
                  <div className='all-product-info-item'>
                  <div className="custom-file-upload" onClick={() => {window.open(item?.invoice_link)}}>
                        <DownloadOutlined/>
                          Download Invoice
                  </div>
                </div>}
                </div>
              </div>
            </div>
          )
        })}

        <Pagination filter={pagination} setFilter={setPagination} />
      </div>
    </div>
  )
}

export default AllLogisticOrders;