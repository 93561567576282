import { Card, Col, Row, Descriptions, Button } from "antd";
import React from "react";
import { calculateItemPrice, loncaFormatDate, loncaRound, renderCalculatedVendorCogs } from "../../../helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InvoiceProduct = ({
    invoiceProduct,
    type,
    addFunction,
    removeFunction,
    buttonName,
    buttonType
}) => {
    const { selectedLanguage } = useSelector((state) => state.user);
    const { t } = useTranslation('translation');
    
    const {
        name,
        name_tr,
        main_image,
        unique_order_id,
        supplier_stock_code,
        quantity,
        item_count,
        payment_at,
        rate_usd_buying, rate_usd_selling, rate_usd_tr_buying, rate_usd_tr_selling,
        price, cogs,
        series,
        item_status,
        is_no_invoice
    } = invoiceProduct

    const cogsText = renderCalculatedVendorCogs({
        payment_at: payment_at, 
        cogs: cogs, 
        rate_usd_tr_buying: rate_usd_tr_buying, 
        rate_usd_buying: rate_usd_buying, 
        rate_usd_selling: rate_usd_selling,
    });

    const unitPrice = calculateItemPrice({ item: { price: price }, order: true });

    const handleAddOrRemove = () => {
        addFunction(prev => [ invoiceProduct, ...prev ]);
        removeFunction(prev => prev?.filter(product => product?.cart_item_id !== invoiceProduct?.cart_item_id));
    }

    return (
        <Card
            hoverable
            className="invoice-product-card"
            bodyStyle={{ padding: "4px" }}
        >
            <Row align="middle">
                <Button type={buttonType} onClick={handleAddOrRemove} className="invoice-product-card-button" disabled={type === "no_invoice" && !is_no_invoice}>
                    {t(buttonName)}
                </Button>
                <Col className="invoice-product-card-container">
                    <img
                        src={main_image}
                    />
                    <Descriptions bordered column={2} className="invoice-product-card-descriptions">
                        <Descriptions.Item labelStyle={{width:'30%'}} label={t("fields.product.Product Name")}>{selectedLanguage?.code === "gb" ? name : name_tr}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.product.Supplier Stock Code")}>{supplier_stock_code}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.product.unique_id")}>{unique_order_id}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.order_products.Payment At")}>
                            {loncaFormatDate(payment_at)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("fields.order_products.Quantity")}>{quantity}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.order_products.Item Count")}>{item_count}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.product.Series")}>{series}</Descriptions.Item>
                        <Descriptions.Item className="invoice-product-card-descriptions-status" label={t("fields.order_products.Status")}>{t(`status.${item_status}`)}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.order_products.COGS")}>{cogsText}</Descriptions.Item>
                        <Descriptions.Item label={t("fields.order_products.Unit Price")}>{`$${unitPrice}`}</Descriptions.Item>
                    </Descriptions>
            
                </Col>
            </Row>
        </Card>
    ) 
}

export default InvoiceProduct;