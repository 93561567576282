import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { ResponsiveImage } from "../../components";
import { Input, Select, Spin, DatePicker, notification, Button, Modal } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Pagination } from '../../components';
import moment from "moment";
import axios from "axios";
import {
  changeCartItem,
  calculateItemPrice,
  loncaRound,
  calculateItemPriceWithCogs,
  loncaFormatDate,
  renderCalculatedVendorCogs,
  calculateVendorCogs
} from '../../helpers';
import {
  ADMIN_VALID_ALL_ORDERS_ITEM_STATUSES,
} from '../../constants'
import {  fetchCalculateAndUpdate, fetchOrderAggregate } from '../../redux/orderSlice';

import "./AllOrders.css";

import LocaleProvider from 'antd/lib/locale-provider';
import tr_locale from "antd/es/date-picker/locale/tr_TR";
import 'moment/locale/tr';
import usePrompt from "../../hooks/usePrompt";
import { t } from "i18next";
import TextArea from "antd/lib/input/TextArea";
import { CSVLink } from "react-csv";


const AllOrders = () => {
  const { selectedLanguage, languagePath, user } = useSelector((state) => state.user);
  const { vendorsWithProject: vendorsMain, subVendors } = useSelector((state) => state.vendors);

  const [orders, setOrders] = useState([]);
  const [initialOrders, setInitialOrders] = useState([]);
  const [vendors, setVendors] = useState(vendorsMain);
  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(-1);
  const [pagination, setPagination] = useState({ page: 1, limit: 100 });
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusfFilter] = useState('');
  const [vendorFilter, setVendorFilter] = useState('');
  const [subVendorFilter, setSubVendorFilter] = useState('');
  const [dateFilter, setDatefFilter] = useState({ start: null, end: null });
  const [invoiceFilter, setInvoiceFilter] = useState('');
  const [stockInFilter, setStockInFilter] = useState('');
  const [changedItems, setChangedItems] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const csvInstance = useRef();

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  usePrompt("You have unsaved changes, do you want to leave?", isDirty);
  const vendorSpecificBoxConfig = {'BSL': 'vendor_product_id'}

  const handleChangeCartItem = ({ item, field, value }) => {
    let newOrders = orders.slice();
    // find index of original item
    let orderIndex = newOrders.findIndex((order) => order?.cart_item?._id === item._id);
    // apply updates and set orders

    const initialItem = initialOrders?.find(initialOrder => initialOrder?.cart_item?._id?.toString() === item?._id?.toString())?.cart_item;

    changeCartItem({
      item : newOrders[orderIndex],
      setItem : (newOrder) => { newOrders[orderIndex] = newOrder },
      field,
      value,
      currentItem : item,
      series : seriesMain,
      isOrderStatus : true,
      initialItem,
      owner : user?.full_name
    });

    setOrders(newOrders);

    // find changed item for same orderId
    let relatedOrder = changedItems?.find(changedItem => changedItem?.orderId?.toString() === newOrders[orderIndex]?._id?.toString());
    // find item of changed item for same orderId and same cart_item id
    let relatedCartItem = null;
    if (relatedOrder) {
      relatedCartItem = relatedOrder?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === newOrders[orderIndex]?.cart_item?._id?.toString())
    }

    if (relatedOrder) {
      if (relatedCartItem) {
        // if related cart item exists, find it and replace with the new version
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems?.filter(cart_item => cart_item?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
              {
                item: {
                  _id: newOrders[orderIndex]?.cart_item?._id,
                  ...relatedCartItem?.item,
                  [field]: value
                },
                type: relatedCartItem?.type === "clone" ? "clone" : "update"
              }
            ]
          }
        ])
      } else {
        // if relatedOrder exists, add updated item to its cartItems
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems,
              {
                item: {
                  _id: newOrders[orderIndex]?.cart_item?._id,
                  [field]: value
                },
                type: "update"
              }
            ]
          }
        ])
      }
    } else {
      // if relatedOrder doesn't exists, add a new changed item with deleted item
      setChangedItems(items => [
        ...items, 
        { 
          orderId: newOrders[orderIndex]?._id, 
          cartItems: [{ 
            item: {
              _id: newOrders[orderIndex]?.cart_item?._id,
              [field]: value
            }, 
            type: "update" 
          }] 
        }
      ]);
    }
    setIsDirty(true);
  }

  const handleSave = ({ forceUpdate = false, conflictCheckOnly = true }) => {

    setSaveLoading(true);

    const beforeUpdateOrders = [];

    for (const order of changedItems) {
      let beforeUpdateOrder = { orderId: order?.orderId, cartItems: [] };

      for (const item of order?.cartItems) {
        let orderItem = initialOrders?.find(o => o?._id?.toString() === order?.orderId?.toString() && o?.cart_item?._id?.toString() === item?.item?._id?.toString());
        let beforeUpdateItem = { item: {}, type: item?.type };

        for (const key of Object.keys(item?.item)) {
          beforeUpdateItem.item[key] = orderItem?.cart_item?.[key];
        }

        beforeUpdateOrder.cartItems.push(beforeUpdateItem);
      }

      beforeUpdateOrders.push(beforeUpdateOrder);
    }

    fetchCalculateAndUpdate(changedItems, beforeUpdateOrders, forceUpdate, conflictCheckOnly)
      .then((res) => {
        setSaveLoading(false);

        if (res?.conflict) {

          const conflictedOrders = orders?.filter(order => 
            res?.nonConflictedCartItems.some(cartItem => order?.cart_item?._id === cartItem?.item?._id)
          );

          let codes = "";
          
          conflictedOrders?.map((order, index) => {
            codes += `${index + 1}-  ${order?.cart_item?.supplier_stock_code}<br />`;  
          })

          const message = t('fields.order_products.overwrite_notif') + "<br />" + codes || "";
          notification['error']({
            message: <div dangerouslySetInnerHTML={{ __html: message }} />,
          });
          
          handleSave({ forceUpdate: false, conflictCheckOnly: false });
          return;
        }

        setIsDirty(false);
        handleRefresh();
        notification['success']({
          message: 'Changes saved successfully',
        });

      })
      .catch((err) => {
        setSaveLoading(false);
        console.log(err)
        notification['error']({
          message: 'Changes couldn\'t saved successfully',
        });
      });
  }


  const handleRefresh = () => {
    setOrders([]);
    setChangedItems([]);
    setIsDirty(false);
    setRefresh(r => !r);
  }


  useEffect(() => {
    setVendors(vendorsMain);
  }, [vendorsMain]);

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    fetchOrderAggregate({
      filter: [
        dateFilter,
        statusFilter,
        invoiceFilter,
        stockInFilter,
        vendorFilter,
        subVendorFilter,
        searchText,
        sort,
        pagination
      ],
      mode: "all_orders",
      cancelToken: source.token
    })
      .then((res) => {
        setOrders(res.data);
        setInitialOrders(res.data);
        setChangedItems([]);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    return () => {
      source.cancel('Operation canceled by the user.');
    }

  }, [sort, pagination?.page, pagination?.limit, statusFilter, searchText, dateFilter, vendorFilter, subVendorFilter, invoiceFilter, refresh, stockInFilter]);

  useEffect(() => {

    setCartItems(orders)

  }, [orders, searchText]);

  const handleExport = async () => {

    setExportLoading(true);
    
    const headers = [
      { label: "Sipariş Numarası", key: "unique_order_id" },
      { label: "Ürün İsmi", key: "product_name" },
      { label: "Tedarikçi Stok Kodu", key: "supplier_stock_code" },
      { label: "Toptancı", key: "vendor" },
      { label: "Toptancı Ürün Linki", key: "supplier_link" },
      { label: "Sipariş Tarihi", key: "order_date" },
      { label: "Sipariş Durumu", key: "order_status" },
      { label: "Ürün Sayısı", key: "item_count" },
      { label: "Paket Sayısı", key: "quantity" },
      { label: "Paket", key: "product_series" },
      { label: "Adet Fiyatı (KDV Hariç)", key: "product_unit_price_without_vat" },
      { label: "Adet Fiyatı (₺) (KDV Dahil)", key: "product_unit_price_with_vat" },
      { label: "Toplam Fiyat (₺) (KDV Dahil)", key: "product_total_price" },
      { label: "Kur Bilgisi", key: "order_currency_rate" },
      { label: "Kullanılan Kupon", key: "used_coupon" },
      { label: "İndirim Miktarı", key: "discount" },
    ];
    
    setExportHeaders(headers);
    const data = orders.map(item => {

      const cogs = calculateVendorCogs({
          payment_at: item?.payment_at, 
          cogs: item?.cart_item?.cogs, 
          rate_usd_tr_buying: item?.rate_usd_tr_buying, 
          rate_usd_buying: item?.rate_usd_buying, 
          rate_usd_selling: item?.rate_usd_selling, 
        })

      return {
        unique_order_id: item?.unique_order_id,
        product_name: item?.cart_item?.product_name,
        supplier_stock_code: item?.cart_item?.supplier_stock_code || (selectedLanguage?.code === "tr" ? item?.cart_item?.product_name_tr : item?.cart_item?.product_name),
        vendor: item?.cart_item?.vendor_name,
        supplier_link: item?.cart_item?.supplier_link || "",
        order_date: loncaFormatDate(item?.payment_at),
        order_status: item?.cart_item?.order_status,
        quantity: item?.cart_item?.quantity,
        item_count: item?.cart_item?.item_count,
        product_series: item?.cart_item?.series,
        product_unit_price_without_vat: renderCalculatedVendorCogs({
                                            payment_at: item?.payment_at, 
                                            cogs: item?.cart_item?.cogs, 
                                            rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                                            rate_usd_buying: item?.rate_usd_buying, 
                                            rate_usd_selling: item?.rate_usd_selling, 
                                        }),
        product_unit_price_with_vat: loncaRound(calculateItemPriceCSV({
          item: {
            ...item, 
            product: { 
              product_type: { vat: item?.cart_item?.vat } 
            }, 
            cogs,
          }, order: true}) 
        ),
        product_total_price: loncaRound(calculateItemPriceCSV({
          item: {
            ...item, 
            product: { 
              product_type: { vat: item?.cart_item?.vat } 
            }, 
            cogs,
          }, order: true})  * item?.cart_item?.item_count * item?.cart_item?.quantity),
        order_currency_rate: item?.rate_usd_tr_selling?.toString() || "",
        used_coupon: item?.coupon?.code || "",
        discount: item?.coupon && item?.coupon?.discount_type === "Percentage" ? 
          `${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${Math.round((calculateItemPriceCSV({
            item: {
              ...item, 
              product: { 
                product_type: { vat: item?.cart_item?.vat } 
              }, 
              cogs,
            }, 
            order: true}
          ) * item?.cart_item?.item_count * item?.cart_item?.quantity) * (item?.coupon?.discount_amount) * 10) / 10}` : "",
      }
    });
    
    setExportData(data);
    setExportLoading(false);
  }

  const calculateItemPriceCSV = ({ item, order = false }) => {
    const cogs = order ? item?.cogs : item?.product?.cogs;
    const vat = item?.product?.product_type?.vat;
  
    return loncaRound(cogs * (1 + vat));
  };

  useEffect(() => {
    if (exportData?.length && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData(false);
      });
    }
  }, [exportData]);

  return (
    <div>
      <Modal 
        title={t('fields.order_products.overwrite_warning_title')}
        visible={showOverwriteWarning}
        width={800}
        onCancel={() => setShowOverwriteWarning(false)}
        footer={[
          <Button
            key="ok" 
            type="secondary"
            onClick={() => { 
              setShowOverwriteWarning(false);
              handleSave({ forceUpdate: true, conflictCheckOnly: false });
            }}
          >
            {t('fields.order_products.save_and_overwrite')}
          </Button>,
          <Button 
            key="cancel" 
            type="primary"
            onClick={() => { 
              setShowOverwriteWarning(false);
              handleSave({ forceUpdate: false, conflictCheckOnly: false });
            }}
          >
            {t('fields.order_products.save_not_overwrite')}
          </Button>
        ]}
      >
        <p>{t('fields.order_products.overwrite_warning')}</p>
      </Modal>
      <div>
        <div className='top-bar'>
          <h2>All Orders</h2>
          <div className='top-bar'>
            <div className='top-btns'>
              <Button className='discard-btn' type='secondary' loading={exportLoading} onClick={handleExport}>{t(`buttons.Export CSV`)}</Button>
              <CSVLink
                  data={exportData || []}
                  headers={exportHeaders || []}
                  filename={`lonca_tum_urunler_${loncaFormatDate(new Date())}.csv`}
                  ref={csvInstance}
              />
              <Button className='save-btn' loading={saveLoading} type='primary' onClick={() => handleSave({ forceUpdate: false, conflictCheckOnly: true })}>Save</Button>
              <Button className='discard-btn' type='primary' onClick={() => {
                if (window.confirm('Do you want do discard all changes?')) handleRefresh();
              }
              }>Discard Changes</Button>
            </div>
          </div>
        </div>

        <div className='all-form-label-bar'>
          <div className='all-search-bar-empty filter-container'></div>
          <div className='filter-container'>
            Vendor
          </div>
          <div className='filter-container'>
            Sub Vendor
          </div>
          <div className=' filter-container'>
            Date Range
          </div>
          <div className='filter-container'>
            Order Status
          </div>
          <div className='filter-container'>
            No Invoice
          </div>
          <div className='filter-container'>
            Stock In
          </div>
          <div className='filter-container'>
            Sort
          </div>
        </div>

        <div className='all-form-bar'>
          <div className="filter-container"><Input className='all-search-bar' placeholder='Search' onChange={(e) => setSearchText(e.target.value)} /></div>

          <div className="filter-container">
            <Select
              showSearch
              optionFilterProp="children"
              required
              onChange={(e) => {
                setVendorFilter(e);
              }}
              placeholder='Vendor'
              style={{ width: "90%" }}
            >
              <Option key="Received-" value="">Any</Option>
              {vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                <Option key={'vendor-select' + index} value={vendor?.name}>
                  {vendor?.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="filter-container">
            <Select 
              showSearch 
              style={{ width: "90%" }}
              placeholder={t('home.Sub Vendor')} 
              defaultValue={''}  
              value={subVendorFilter} 
              onChange={setSubVendorFilter}
            >
              <Option key={'any'} value={''}>{t(`status.Any`)}</Option>
                {subVendors?.map((subVendor) => subVendor?.name)?.slice()
                  ?.sort((a, b) => a?.localeCompare(b))
                  ?.map((item) => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
            </Select>
          </div>

          <div className="filter-container"><LocaleProvider >
            <RangePicker
              locale={languagePath === "" ? tr_locale : null}
              showTime
              value={[moment(dateFilter?.start || new Date()), moment(dateFilter?.end || new Date())]}
              onChange={([start, end]) => {
                setDatefFilter({ start: new Date(start._d), end: new Date(end._d) })
              }}
              style={{ width: "200px" }}
            />
          </LocaleProvider></div>

          <div className="filter-container" ><Select placeholder='Status' style={{ width: "90%" }} defaultValue={''} onChange={setStatusfFilter}>
            {
              ['Any', ...ADMIN_VALID_ALL_ORDERS_ITEM_STATUSES].map((status) => {
                return <Option key={status} value={status === "Any" ? '' : status}>{status}</Option>
              })}
          </Select></div>

          <div className="filter-container">
            <Select style={{ width: "90%" }} defaultValue={''} onChange={setInvoiceFilter}>
              <Option key='all' value=''>Any</Option>
              <Option key='entered-with-invoice' value={true}>Yes</Option>
              <Option key='entered-without-invoice' value={false}>No</Option>
            </Select>
          </div>

          <div className="filter-container">
            <Select style={{ width: "90%" }} defaultValue={''} onChange={setStockInFilter}>
              <Option key='all' value=''>Any</Option>
              <Option key='is-stock-in' value={true}>Yes</Option>
              <Option key='not-stock-in' value={false}>No</Option>
            </Select>
          </div>

          <div className="filter-container"><Select placeholder='Sort' style={{ width: "90%" }} defaultValue={-1} onChange={setSort}>
            <Option key='new-to-old' value={-1}>New to Old</Option>
            <Option key='old-to-new' value={1}>Old to New</Option>
          </Select></div>
        </div>
      </div>

      <div className='product-table'>
        {loading && <Spin tip={"Loading Products..."} />}

        {cartItems?.map((item, index) => {
          const unitPrice = calculateItemPrice({
            item: {
              ...item,
              cogs: item?.cart_item?.cogs,
              price: item?.cart_item?.price,
              product: { product_type: { vat: item?.cart_item?.vat } }
            }, order: true
          });

          const hasChanged = changedItems?.find(changedItem => changedItem?.orderId?.toString() === item?._id && changedItem?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === item?.cart_item?._id?.toString()));
          const itemDate = item?.cart_item?.order_addition_date ? item?.cart_item?.order_addition_date : item?.payment_at;

          return (
            <div className={`product-container all-product-container ${hasChanged && 'changed'}`} key={item._id + index}>
              <div className="item-image">
                <ResponsiveImage className='product-image' height={348} src={item?.cart_item?.productMainImage} alt="product" />
              </div>

              <div className="all-item-info">
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Customer</span>
                    <input className='ant-input' value={item?.customer_name} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unique Order ID</span>
                    <Input disabled value={item?.unique_order_id}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Order Date</span>
                    <Input disabled value={loncaFormatDate(itemDate)}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='all-product-info-label'>{t(`fields.order_products.Supplier Notes`)}</span>
                    <TextArea rows={1} onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'notes', value: e.target.value })
                    } value={item?.cart_item?.notes} disabled/>
                  </div>
                  <div className='all-product-info-item'>
                    <span className='all-product-info-label'>{t(`fields.order_products.Admin Notes`)}</span>
                    <TextArea rows={1} onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'notes_admin', value: e.target.value })
                    } value={item?.cart_item?.notes_admin} />
                  </div>
                </div>
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Vendor</span>
                    <Input disabled value={item?.cart_item?.vendorName + (item?.cart_item?.sub_vendor_name ? ` - ${item?.cart_item?.sub_vendor_name}` : "")} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Name</span>
                    <Input disabled value={item?.cart_item?.productName} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Supplier Code</span>
                    <Input disabled value={item?.cart_item?.productSupplierStockCode}
                    />
                  </div>
                  <div className='allproduct-info-item'>
                  <span className='product-info-label'>{t(`fields.product.Supplier Link`)}
                    <span
                      className='copy-icon'
                      onClick={() => navigator.clipboard.writeText(item?.cart_item?.productSupplierLink)}
                    >
                      <CopyOutlined />
                    </span>
                  </span>
                  <TextArea rows={2} disabled value={item?.cart_item?.productSupplierLink} />
                </div>
                 
                </div>
                <div className="all-info-row">
                <div className='all-product-info-item'>
                    <span className='product-info-label'>Status</span>
                    <input className='ant-input' value={item?.cart_item?.order_status} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Series</span>
                    <Input disabled value={item?.cart_item?.series}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Quantity</span>
                    <Input disabled value={item?.cart_item?.quantity}
                    />
                  </div>
                  <div className='all-product-info-item'>
                  <span className='product-info-label'>{t(`fields.order_products.Item Count`)}</span>
                  <input className='ant-input' placeholder='Item Count' value={item?.cart_item?.item_count} disabled />
                </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price</span>
                    <input className='ant-input' value={`$${unitPrice}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit COGS</span>
                      <input className='ant-input' 
                      placeholder='COGS' 
                      value={renderCalculatedVendorCogs({
                        payment_at: item?.payment_at, 
                        cogs: item?.cart_item?.cogs, 
                        rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                        rate_usd_buying: item?.rate_usd_buying, 
                        rate_usd_selling: item?.rate_usd_selling, 
                      })} 
                      disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Total Supplier Payment (VAT Included)</span>
                    <input className='ant-input' placeholder='Item Count' value={`${(!item?.rate_usd_buying || !item?.rate_usd_tr_buying || !item?.rate_usd_selling) ? "$" : "₺"}${loncaRound(calculateItemPriceWithCogs({
                      item: {
                        ...item,
                        product: {
                          product_type: { vat: item?.cart_item?.vat }
                        },
                        cogs: calculateVendorCogs({
                          payment_at: item?.payment_at, 
                          cogs: item?.cart_item?.cogs, 
                          rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                          rate_usd_buying: item?.rate_usd_buying, 
                          rate_usd_selling: item?.rate_usd_selling, 
                        })
                      },
                      order: true
                    }) * item?.cart_item?.item_count * item?.cart_item?.quantity)}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span>No Invoice</span>
                    <input style={{ "marginTop": "5px" }} type="checkbox" value={item?.cart_item?.entered_without_invoice} checked={item?.cart_item?.entered_without_invoice}
                      onChange={(e) => {
                        handleChangeCartItem({ item: item?.cart_item, field: 'entered_without_invoice', value: e.target.checked })
                      }} />
                  </div>
                  {item?.cart_item?.is_stock_in && 
                    <div className='all-product-info-item'>
                      <span>Is Stock In</span>
                      <input style={{ "marginTop": "5px" }} type="checkbox" value={item?.cart_item?.is_stock_in} checked={item?.cart_item?.is_stock_in}
                        onChange={(e) => {
                          handleChangeCartItem({ item: item?.cart_item, field: 'is_stock_in', value: e.target.checked })
                        }} />
                    </div>
                  }
                  {item?.cart_item?.status_log?.length > 0 
                    ? <>
                      <div className='all-product-info-item'>
                        <span className='product-info-label'>Last Status Changed By</span>
                        <input className='ant-input' value={item?.cart_item?.status_log?.[item?.cart_item?.status_log?.length - 1]?.owner} disabled />
                      </div>
                      <div className='all-product-info-item'>
                        <span className='product-info-label'>Last Status Change</span>
                        <input className='ant-input' value={item?.cart_item?.status_log?.[item?.cart_item?.status_log?.length - 1]?.status} disabled />
                      </div> 
                    </>
                    : null 
                  }
                  {Object.keys(vendorSpecificBoxConfig)?.includes(item?.cart_item?.vendorName) && <div className='all-product-info-item'>
                        <span className='product-info-label'>Vendor Specific Field</span>
                        <input className='ant-input' value={item?.cart_item?.[vendorSpecificBoxConfig[item?.cart_item?.vendorName]]} disabled />
                      </div> 
                  }
                </div>
              </div>
            </div>
          )
        })}

        <Pagination filter={pagination} setFilter={setPagination} />
      </div>
    </div>
  )
}

export default AllOrders;